import React from "react"
import styles from "./blockImageLeftBigTitleText.module.css"

const BlockImageLeftBigTitleText = ({ bgColor, data }) => {
  const {
    title,
    text,
    img: {
      uri: { url: image },
    },
  } = data
  return (
    <section className={styles.blockImageLeftBigTitleText}>
      <div className={styles.blockImageLeftBigTitleText__img}>
        <img src={`https://drupal.smartempo.com${image}`} alt={title} />
      </div>
      <div className={styles.blockImageLeftBigTitleText__titleText}>
        <h1>{title}</h1>
        <p>{text}</p>
      </div>
    </section>
  )
}
export default BlockImageLeftBigTitleText
