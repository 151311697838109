import { useStaticQuery, graphql } from "gatsby"

export const useContactPage = () => {
  const { allNodeContactPage } = useStaticQuery(
    graphql`
      query ContactPage {
        allNodeContactPage {
          edges {
            node {
              field_form_text_right_text_
              field_form_text_right_title_
              field_imageleftbigtitletext_text
              field_imageleftbigtitletext_titl
              relationships {
                field_imageleftbigtitletext_img_ {
                  uri {
                    url
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeContactPage.edges[0].node
}
