import React from "react"

import FullScreenMenu from "../components/FullScreenMenu/FullScreenMenu"
import Topbar from "../components/Topbar/Topbar"
import BlockImageLeftBigTitleText from "../components/BlockImageLeftBigTitleText/BlockImageLeftBigTitleText"
import BlockFormTextRight from "../components/BlockFormTextRight/BlockFormTextRight"
import BlockTextBackground from "../components/BlockTextBackground/BlockTextBackground"

import Footer from "../components/Footer/Footer"

import { useContactPage } from "../hooks/use-contactPage"

const ContactUs = () => {
  const contactData = useContactPage()
  const imageLeftBigTitleTextData = {
    title: contactData.field_imageleftbigtitletext_titl,
    text: contactData.field_imageleftbigtitletext_text,
    img: contactData.relationships.field_imageleftbigtitletext_img_,
  }
  const formTextRightData = {
    title: contactData.field_form_text_right_title_,
    text: contactData.field_form_text_right_text_,
  }

  return (
    <div className="theContainer">
      <FullScreenMenu />
      <div id="container" className="container">
        <Topbar />
        <BlockImageLeftBigTitleText data={imageLeftBigTitleTextData} />
        <BlockFormTextRight data={formTextRightData} />
        {/* <BlockTextBackground /> */}
        <Footer />
      </div>
    </div>
  )
}

export default ContactUs
