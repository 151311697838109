import React from "react"
import styles from "./blockFormTextRight.module.css"
import BlockContactForm from "../BlockContactForm/BlockContactForm"

const BlockFormTextRight = ({ bgColor, data }) => {
  const { title, text } = data
  return (
    <section className={styles.blockFormTextRight}>
      <div className={styles.blockFormTextRight__form}>
        <BlockContactForm />
      </div>
      <div className={styles.blockFormTextRight__text}>
        {/* <h4>{title}</h4>
        <p>{text}</p>
        <div className={styles.blockFormTextRight__text__subscribe}>
          <label for="email">
            <input type="text" name="email" placeholder="Email Address" />
            <button>Subscribe</button>
          </label>
        </div> */}
      </div>
    </section>
  )
}
export default BlockFormTextRight
